import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import logoVitaFons from '../../../static/logoVitaFons.jpg'
import poudre1 from '../../../static/poudre1.jpg'
import poudre2 from '../../../static/poudre2.jpg'
import StyledPagePoudreFoliaire from './StyledPagePoudreFoliaire';

export const PagePoudreFoliaire = () => {
    const { pagePoudreFoliaire } = useStaticQuery(
        graphql`
            query {
                pagePoudreFoliaire:datoCmsPagePoudreFoliaire {
                    encart1Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart2Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart3Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                  }
            }
          `)
    const { titlePage } = useContext(GlobalStateContext)
    return (
        <StyledPagePoudreFoliaire>
            <div className='container-page'>
                <div className='wrapper-titleh3'>
                    <h3>{titlePage}</h3>
                </div>
                <div className='container-img-txt'>
                    <img id='poudre1' alt='poudre1' src={poudre1} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pagePoudreFoliaire.encart1Node.childMarkdownRemark.html}} />
                    <img id='logo' alt='logo' src={logoVitaFons} />
                </div>
                <div className='little-separator' />
                <div className='container-img-txt2'>
                    <div className='wrapper-txt'>
                        <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pagePoudreFoliaire.encart2Node.childMarkdownRemark.html}} />
                        <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pagePoudreFoliaire.encart3Node.childMarkdownRemark.html}} />
                    </div>
                    <div className='wrapper-img-btn'>
                        <img alt='poudre2' src={poudre2} />
                        <Link className='btnToHome' to='/'>Voir les Produits</Link>
                    </div>
                </div>
            </div>
        </StyledPagePoudreFoliaire>
    )
}
